import React from 'react';
import './text.css';

const Text = ({ th, td }) => {
    return (
        <div className="text">
            <div className="text-content">
                <div className="thead">
                    <p >{th}</p>
                </div>
                <div className="tdesc">
                    <p>{td}</p>
                </div>
            </div>
        </div>
    );
};

export default Text;
