import React from 'react'
import { Introduction, Vision, Choose } from '../../components'
import './about.css'

function About() {
    return (
        <div className='resume section-margin' id='about'>
        <h1 className='gradient-text'>About Us</h1>
        <Vision />
        <Introduction />
        <Choose/>
        </div>
    )
}

export default About
