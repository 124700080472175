import React from 'react';
import './service.css';

function Service({ icon, title }) {
    return (
        <div className='service-grid'>
            <div className='service-grid-icon'>
                <img src={icon} alt="Icon" />
            </div>
            <h2 className='service-grid-h2'>{title}</h2>
        </div>
    );
}

export default Service;
