import React from 'react';
import ReactDOM from 'react-dom';
import "./web.config";

import App from './App';
import { Bim, Civil, Electrical, Infrastructure, Mechanical, Mep, Piping, Project, ToolAndDie, Visual } from './components'
import {Life} from './containers'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css'

ReactDOM.render(

    <Router>
    <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Life" element={<Life/>}/>
        <Route path="/Bim" element={<Bim />} />
        <Route path="/Civil" element={<Civil />} />
        <Route path="/Electrical" element={<Electrical />} />
        <Route path='/Infrastructure' element={<Infrastructure />} />
        <Route path="/Mechanical" element={<Mechanical />} />
        <Route path="/Mep" element={<Mep />} />
        <Route path="/Piping" element={<Piping />} />
        <Route path="/Project" element={<Project />} />
        <Route path="/ToolAndDie" element={<ToolAndDie />} />
        <Route path="/Visual" element={<Visual />} />
    </Routes>
</Router>, 

document.getElementById('root'));