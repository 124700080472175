import React from 'react';
import './vision.css';
import { useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';

function Vision() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const fadeIn = useSpring({ opacity: inView ? 1 : 0, from: { opacity: 0 }, config: { duration: 2000 } });

  return (
    <div className='vision' ref={ref}>
      <p><br /><br /><br /></p>
      <h4 className='he'>VISION</h4>
      <p className='vision-text' style={fadeIn}>To be the leading engineering education provider
        that provides transformative education using
        cutting-edge technology and innovative
        engineering methods for preparing students for a
        career in the global workforce.
      </p>
      <p><br /><br /><br /></p>
      <h4 className='he'>MISSION</h4>
      <p className='vision-text' style={fadeIn}>- To create an ambiance in which students can
        learn and develop. <br />
        - To provide education that transforms students
        through rigorous coursework and by providing
        an understanding of the needs of the industry.<br />
        - To collaborate with other professionals across
        to strengthen the education and research
      </p>
      <p><br /><br /><br /><br /><br /><br /><br /></p>
    </div>
  );
}

export default Vision;
