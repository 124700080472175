import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/infra1.jpg';
import Two from '../../../assets/infra2.jpg';
import Three from '../../../assets/infra3.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Infrastructure() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='infra'>
                <div className='bimH'>
                    <h2>INFRASTRUCTURE DESIGNING</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="AUTO DESK CIVIL 3D"
                        description="Autodesk Civil 3D software is a civil engineering design and documentation solution that supports Building Information Modeling (BIM) workflows on a variety of civil infrastructure project types,including roads and highways,land development, rail,airports,and water."
                    />
                    <Card imageUrl={Two}
                        cardhead="MICROSTATION"
                        description="Micro Station is the world's leading 2D and 3D computer aid design and visualization software for architecture,engineering, construction, and operation of all infrastructure types. Architects,engineers,design professionals,and draftsman can all use MicroStation."
                    />
                    <Card imageUrl={Three}
                        cardhead="INFRAWORKS"
                        description="InfraWorks 360 preliminary engineering and desing software improves your infrastructure project outcomes throughout the asset lifecycle.Make decisions in the context of the real world, not the CAD world"
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Infrastructure
