import React from 'react';
import Header from './Lifeheader';
import './LifeAtCaddWorld.css';
import logo from '../../assets/logo.jpg';
import { Footer } from '../../containers';

import a from '../../assets/Life At Cadd World/a1.jpg';
import b from '../../assets/Life At Cadd World/a2.jpg';
import c from '../../assets/Life At Cadd World/a3.jpg';
import d from '../../assets/Life At Cadd World/a4.jpg';
import e from '../../assets/Life At Cadd World/a5.jpg';
import f from '../../assets/Life At Cadd World/a6.jpg';
import g from '../../assets/Life At Cadd World/a7.jpg';
import h from '../../assets/Life At Cadd World/a8.jpg';
import i from '../../assets/Life At Cadd World/a9.jpg';
import j from '../../assets/Life At Cadd World/a10.jpg';
import k from '../../assets/Life At Cadd World/a11.jpg';
import l from '../../assets/Life At Cadd World/a12.jpg';
import m from '../../assets/Life At Cadd World/a13.jpg';
import n from '../../assets/Life At Cadd World/a14.jpg';
import o from '../../assets/Life At Cadd World/a15.jpg';
import p from '../../assets/Life At Cadd World/a16.jpg';
import q from '../../assets/Life At Cadd World/a17.jpg';
import r from '../../assets/Life At Cadd World/a18.jpg';
import s from '../../assets/Life At Cadd World/a19.jpg';
import t from '../../assets/Life At Cadd World/a20.jpg';
import u from '../../assets/Life At Cadd World/a21.jpg';

function LifeAtCaddWorld() {
    const images = [a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u];

    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='life'>
                <div className='lifeH'>
                    <h2>LIFE AT CADDWORLD</h2>
                </div>
                <div>
                    <div className='lifecard-container'>
                        {images.map((image, index) => (
                            <div key={index} className='lifecard'>
                                <img src={image} alt={`lifecard-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    );
}

export default LifeAtCaddWorld;
