import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/visual1.jpg';
import Two from '../../../assets/visual2.jpg';
import Three from '../../../assets/visual3.jpg';
import Four from '../../../assets/visual4.jpg';
import Five from '../../../assets/visual5.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Visual() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='visual'>
                <div className='bimH'>
                    <h2>DESIGN VISUALIZATION</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="3DS MAX"
                        description="3ds Max is used to model, animate, and render detailed 3D characters, photorealistic designs, and complex scenes for film and TV, games, and design visualization projects."
                    />
                    <Card imageUrl={Two}
                        cardhead="VRAY"
                        description="V-Ray is a commercial plug-in for third- party 3D computer graphics software applications and is used for visualizations and computer graphics in industries such as media, industrial design, product design and architecture."
                    />
                </div>
            </div>
            <div className='gap' />
            <div className='visual2'>
                <div className='bimH'>
                    <h2>DESIGN VISUALIZATION</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={Three}
                        cardhead="SKETCH UP"
                        description="Sketch up is an easy, elegant 3D modeling program Starting with basic shapes and forms, you can build anything from sky scrapers to 3D-printable product mockups. From a notion a concept, an idea, whatever your little heart desires."
                    />
                    <Card imageUrl={Four}
                        cardhead="LUMION"
                        description="Lumion empowers you to express your vision as an image, video or 360 panorama, and it renders the results faster than most other 3D rendering programs out there. Lumion is designed to be effortless, transparent and stress-free."
                    />
                    <Card imageUrl={Five}
                        cardhead="RHINOCEROUS"
                        description="It is an accurate 3D modeling software, perfect to design or for engineering analysis and it also provides deep analysis and visualization features. Rhino 3D modeling software can also help you generate great photorealistic rendering and makes animation or illustrations."
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Visual
