import React from 'react'
import { Service } from '../../components'
import './services.css'

//Icons

import { Software, D3, Design, Cloud, Project, BIM, Drawing, Drafting } from '../../assets'
function Services() {
    return (
        <div className='services section-margin' id='services'>
        <h1 className='gradient-text'>Services Offered</h1>
        <div className='services-container'>
            <Service icon={Software} title='Training services for softwares' />
            <Service icon={D3} title='3D Modelling Services' />
            <Service icon={Design} title='Design Support to the clients' />
            <Service icon={Cloud} title='Point Cloud Data Modelling Services' />
            <Service icon={Project} title='Project Management Services' />
            <Service icon={BIM} title='BIM Modelling Services' />
            <Service icon={Drawing} title='Building submission drawing preparation and approval' />
            <Service icon={Drafting} title='Drafting Services' />
        </div>
        
        </div>
    )
}

export default Services
