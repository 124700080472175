import React from 'react'

import { Footer, Courses, Services, Hero, About, } from './containers'
import { Contact, Navbar } from './components'

import './App.css'

const App = () => {
    return (
        <div className='App'>
            <div className='gradient-bg'>
                <Navbar />
                <Hero />
            </div>
            <About />
            <Services />
            <Courses />
            <Contact />
            <Footer />

        </div>
    )
}

export default App
