import React from 'react'
import './course.css'
import { Link } from 'react-router-dom';

function Course() {
    return (
        <div className='course'>
            <div className='course-heading'>
                <h2>OVERALL COURSE DETAILS</h2>
            </div>
            <div className='btn'>
            <Link to={'/Bim'} target='_top'><button className='btn1'>BIM</button></Link>
            <Link to={'/Civil'} target='_top'><button className='btn2'>CIVIL</button></Link>
            <Link to={'/Infrastructure'} target='_top'><button className='btn3'>INFRASTRUCTURE DESIGNING</button></Link>
            <Link to={'/Visual'} target='_top'><button className='btn4'>DESIGN VISUALIZATION</button></Link>
            <Link to={'/Mechanical'} target='_top'><button className='btn5'>MECHANICAL</button></Link>
            <Link to={'/Mep'} target='_top'><button className='btn6'>MEP</button></Link>
            <Link to={'/electrical'} target='_top'><button className='btn7'>ELECTRICAL</button></Link>
            <Link to={'/Piping'} target='_top'><button className='btn8'>PIPING</button></Link>
            <Link to={'/Project'} target='_top'><button className='btn9'>PROJECT MANAGEMENT</button></Link>
            <Link to={'/ToolAndDie'} target='_top'><button className='btn10'>TOOL AND DIE</button></Link>
            </div>
            <p><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></p>
        </div>
    )
}

export default Course
