import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/project1.jpg';
import Two from '../../../assets/project2.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Project() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='project'>
                <div className='bimH'>
                    <h2>PROJECT MANAGEMENT</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="PRIMAVERA PG"
                        description="Primavera P6 is a project management software application used in a variety of industries, including construction, engineering, and manufacturing. It is a comprehensive tool for planning, scheduling, and tracking projects of any size or complexity, allowing project managers to effectively manage resources, budgets, and timelines."
                    />
                    <Card imageUrl={Two}
                        cardhead="MICROSOFT PROJECT"
                        description="Microsoft Project is a project management software application developed by Microsoft Corporation. It is used by project managers in a variety of industries to plan, track, and manage projects of varying complexity."
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Project
