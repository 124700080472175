import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/mep1.jpg';
import Two from '../../../assets/mep2.jpg';
import Three from '../../../assets/mep3.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Mep() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='mep'>
                <div className='bimH'>
                    <h2>MEP</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="HVAC"
                        description="HVAC stands for Heating, Ventilation, and Air Conditioning t refers to the systems and technologies used in buildings to regulate indoor temperature, humidity, and air quality for the comfort and safety of occupants."
                    />
                    <Card imageUrl={Two}
                        cardhead="PLUMBING"
                        description="Plumbing refers to the system of pipes, fixtures, and other apparatuses used for the distributor of water and removal of waste in a building or structure. Plumbing is essential for modern living as it provides clean water for consumption, bathing, and other household needs while also disposing of waste materials safely and efficiently."
                    />
                    <Card imageUrl={Three}
                        cardhead="FIREFIGHTING"
                        description="Firefighting is the act of extinguishing or preventing the spread of unwanted fires from threatening human lives and destroying property and the environment."
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Mep
