import React from 'react'
import { SocialMedia } from '../../components'
import './footer.css'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';

function Footer() {
    return (
        <>
            <div className='footer section-padding'>
                <div className='contact-details'>
                    <div className='contact-details-wrapper'>
                        <div className='email contact-details-child'>
                            <EmailIcon fontSize='large' sx={{ color: '#fff' }} />
                            <p>Info@caddworldgroup.com</p>
                        </div>
                        <div className='phone contact-details-child'>
                            <CallIcon fontSize='large' sx={{ color: '#fff' }} />
                            <p>+91 773 663 4445 <br /> +91 773 663 4446 <br /> +91 773 663 4447 <br /></p>
                        </div>
                        <div className='address contact-details-child'>
                            <LocationOnIcon fontSize='large' sx={{ color: '#fff' }} />
                            <p>Sky Tower, 5th floor, Bank Road <br /> Mavoor Road Jn., (Opp. Kurishu Pally) <br /> Kozhikode 673 001</p>
                        </div>
                        <div>
                            <a href='https://www.google.com/maps/dir//5th+Floor,+CADD+WORLD,+Sky+Tower,+Bank+Rd,+opposite+to+KurishuPally,+Mavoor+Road+Junction,+Tazhekkod,+Kozhikode,+Kerala+673001/@11.2593125,75.7805296,21z/data=!3m1!5s0x3ba65936ffb6cb17:0xd4cff70b3f9693a3!4m8!4m7!1m0!1m5!1m1!1s0x3ba6597e970996f5:0xb612ae0acf263982!2m2!1d75.7805853!2d11.259319?entry=ttu' target='_self'>
                                <button className='directions'>GET DIRECTIONS</button>
                            </a>
                        </div>
                    </div>
                </div>
                <SocialMedia />
                <div className='footer-copyright'>
                    <p> CADD WORLD 2023</p>
                </div>
            </div>
        </>
    )
}

export default Footer
