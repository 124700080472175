import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import Text from './constants/text';
import One from '../../../assets/bim1.jpg';
import Two from '../../../assets/bim2.jpg';
import Three from '../../../assets/bim3.jpg';
import Four from '../../../assets/bim4.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Electrical() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='bim'>
                <div className='bimH'>
                    <h2>BUILDING INFORMATION MODELLING</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="BIM FOR MANAGER"
                        description="Building information Modeling (BIM) is a process that begins with creating an intelligent 3D design model and then uses that model to facilitate coordination, simulation, and visualization, as well as helping owners and service providers improve how buildings and infrastructure are planned, designed, built, and managed. The training willbe based on 1501 9650 standards"
                    />
                    <Card imageUrl={Two}
                        cardhead="BIM FOR ARCHITECTURE"
                        description="BIM (Building Information Modeling) architecture is an approach to architectural design and construction that utilizes a three-dimensional, digital model to coordinate and integrate all aspects of the building process, from design and construction to maintenance and operations."
                    />
                </div>
            </div>
            <div className='gap' />
            <div className='bim2'>
                <div className='bimH'>
                    <h2>BUILDING INFORMATION MODELLING</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={Three}
                        cardhead="BIM FOR COORDINATOR"
                        description="The BIM Coordinator's primary role is to ensure that all project stakeholders (including architects, engineers, contractors, and owners) are working collaboratively and effectively with the BIM model.This Course trains you to develop your career as a BIM coordinator."
                    />
                    <Card imageUrl={Four}
                        cardhead="BIM FOR MEP"
                        description="BIM MEP (Mechanical, Electrical, and Plumbing) refers to the use of Building Information Modeling (BIM) software and techniques to design and coordinate mechanical, electrical, and plumbing systems within a building or construction project."
                    />
                </div>
            </div>
            <div className='gap' />
            <div className='bim3'>
                <div className='bimH'>
                    <h2>BUILDING INFORMATION MODELLING</h2>
                </div>
                <div className='bimtext'>
                    <Text th='WHAT IS BIM?' td='Building Information Modelling (BIM) is an advanced and intelligent 30 model-based process that helps architects, engineers and construction professions to efficiently create. plan, design and manage every aspect of the buildings and infrastructure.' />
                    <Text th='THE BIM MANDATES IN THE GLOBAL AEC INDUSTRY' td='BIM mandates are increasing all over the world. Nations like the UK, the US, South Korea, Japan, the Netherlands, Singapore, and Australia, and a few other countries have mandated the use of BIM in all high-rise projects and in all government projects.' />
                    <Text th='BIM - AN IDEAL CAREER OPTION' td='In short, BIM is a very viable career option, it is emerging as an innovative way to virtually design and manage projects. In India alone, there are more than 10,000 vacancies for BIM managers and in other construction-firms. BIM manager plays a vital role in AEC tasks. So, to become an expert BIM manager, War BM and ine it in the architecture and construction design while honing other required skills.' />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Electrical
