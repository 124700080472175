import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/autocad.jpg';
import Two from '../../../assets/staadpro.jpg';
import Three from '../../../assets/Etabs.jpg';
import Four from '../../../assets/Tekla.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Civil() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='civil'>
                <div className='bimH'>
                    <h2>CIVIL ENGINEERING</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="AUTOCAD"
                        description="AutoCAD is a computer-aided design (CAD) software that is used for precise 2D and 3D drafting, design and modelling with solids, surfaces, mesh objects, documentation features."
                    />
                    <Card imageUrl={Two}
                        cardhead="STAAD PRO"
                        description="STAAD Pro is one of the popular software that is used for analysing and designing structures like - buildings, towers, bridges, industrial, transportation and utility structures."
                    />
                </div>
            </div>
            <div className='gap' />
            <div className='civil2'>
                <div className='bimH'>
                    <h2>CIVIL ENGINEERING</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={Three}
                        cardhead="ETABS"
                        description="ETABS is an engineering software product that caters to multi- story building analysis and design. Modelling tools and templates, code-based load prescriptions, analysis methods and solution techniques, all coordinate with the grid-like geometry unique to this class of structure."
                    />
                    <Card imageUrl={Four}
                        cardhead="TEKLA"
                        description="Tekla Structures is used in the construction industry for steel and concrete detailing, precast and cast in-situ. The software enables users to create and manage 3D structural models in concrete or steel, and guides them through the process from concept to fabrication. The process of shop drawing creation is automated."
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Civil
