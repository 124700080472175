import React from 'react';
import './choose.css';

function Choose() {
    return (
        <div className='choose'>
            <div className='choose-heading'>
                <h2>WHY CHOOSE US</h2>
            </div>

            <p className='choose-text'> Managing partners are diverse industry experts with broad deep specific industry experience from different geographical areas and who can mentor students to right path to become industry professionals.<br /> <br />

                During course period students gets interactive sessions with world class MNC working professionals to understand the real industry world. We also provide opportunity to understand and involve in live projects for experiencing actual technical capability requirement, to understand importance of communication skills. quality management, time and schedule management which will tailor student to easily adapt while entering into industry. <br /> <br />

                We help students to prepare for entering into job hunting by skill pool talent identification, CV preparation, job website profile creation assistance and placement assistance to middle east & South East Asia as management have deep rooted connection with these regions. <br /> </p>
                <p><br /><br /><br /><br /><br /><br /><br /></p>

        </div>
    );
}

export default Choose;
