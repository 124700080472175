import React, { useState } from 'react'
import './courseheader'

import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'

const Menu = () => (
  <>
    <p><a href='/#courses'>Home</a></p>
  </>
)

function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='portfolio-navbar'>
      <div className='portfolio-navbar-links'>
        <div className='portfolio-navbar-links-container'>
          <Menu />
        </div>
        <div className='portfolio-navbar-menu'>
          {toggleMenu ? <RiCloseLine color='#d30004' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#d30004' size={27} onClick={() => setToggleMenu(true)} />
          }
          {toggleMenu && (
            <div className='portfolio-navbar-menu-container scale-up-tr'>
              <div className='portfolio-navbar-menu-container-links'>
                <Menu />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
