import React from 'react';
import './hero.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.jpg';
import auto1 from '../../assets/autodesk1.svg';
import auto2 from '../../assets/autodesk2.svg';
import hero_image from '../../assets/chess.png';
import TawkToWidget from '../../components/chat';

function Hero() {
    return (
        <>
            <div className='hero section-padding' id='home'>
                <div className='hero-content'>
                    <img src={logo} alt='logo' className='hero-logo' />
                    <h1 className='gradient-text'>We are Cadd World</h1>
                    <text className='hero-tag'>Elevating the Art of Delivering Excellence</text>
                    <div className='hero-autos'>
                        <img src={auto1} alt='auto' className='hero-auto' />
                        <img src={auto2} alt='auto2' className='hero-auto' />
                    </div>
                    <Link to={'/Life'} target='_top'><button className='explore-btn'>Life At Cadd World</button></Link>
                </div>
                <div className='hero-image'>
                    <img src={hero_image} alt='Hero' />
                </div>
            </div>
            <TawkToWidget />
        </>
    )
}

export default Hero;
