import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/toolanddie1.jpeg';
import Two from '../../../assets/toolanddie2.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function ToolandDie() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='tool'>
                <div className='bimH'>
                    <h2>TOOL & DIE</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="MOLD DESIGN"
                        description="Mold design is the process of creating a design or blueprint for the manufacture of a mold, which is used to produce a specific part or component.Molds are used in a variety of industries,includeing automotive, aerospace, medical, and consumer products."
                    />
                    <Card imageUrl={Two}
                        cardhead="DIE CASTING"
                        description="Die casting is a metal casting process that involves injecting molten metal into a mold or die cavity under high pressure. The mold or die is typically made of steel and is designed to produce complex shapes and geometries with high precision and repeatability."
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default ToolandDie
