import React from 'react';
import './intro.css';
import { useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';

function Introduction() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const fadeIn = useSpring({ opacity: inView ? 1 : 0, from: { opacity: 0 }, config: { duration: 2000 } });

  return (
    <div className='intro' ref={ref}>
      <div className='intro-heading'>
        <h2 style={fadeIn}>Introduction</h2>
      </div>
      <p className='intro-text' style={fadeIn}>CADD WORLD is a professional institute in CAD training
        as an Autodesk Authorised centre (ATC). We are also 
        providing a wide range of Engineering supports to clients. 
        We aim to provide a World Class Learning Environment in 
        Latest Softwares. Our courses are designed and molded 
        to ensure that the specific and specialized requirements of 
        the industry are met. The course content is developed and 
        designed based on inputs from software developers and 
        industry experts to get swift adaptability of the industry. 
        We have strived to ensure that quality is not compromised. 
        The high standards set by us are appreciated and accepted.
      </p>
      <p><br /><br /><br /><br /><br /><br /><br /></p>
    </div>
  );
}

export default Introduction;
