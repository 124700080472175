import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/mech1.jpg';
import Two from '../../../assets/mech2.jpg';
import Three from '../../../assets/mech3.jpg';
import Four from '../../../assets/mech4.jpg';
import Five from '../../../assets/mech5.jpg';
import Six from '../../../assets/mech6.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Mechanical() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='mech'>
                <div className='bimH'>
                    <h2>MECHANICAL</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="CATIA"
                        description="CATIA is a computer-aided design (CAD) software package developed by the French compony Dassault Systèmes. It is widely used in the aerospace, automotive, industrial machinery, and other industries for designing complex mechanical and engineering products."
                    />
                    <Card imageUrl={Two}
                        cardhead="CREO"
                        description="CREO is a computer-aided design (CAD) software package developed by PTC (Parametric Technology Corporation). It is widely used in industries such as aerospace, automotive, manufacturing, and engineering for designing and developing complex mechanical and engineering products."
                    />
                    <Card imageUrl={Three}
                        cardhead="SOLID WORKS"
                        description="SOLIDWORKS allows designers and engineers to create 30 models of parts and assemblies, perform simulations and analysis, and generate engineering drawings and documentation. The software incudes a range of tools and modules for designing and analyzing mechanical, electrical, and plastic injection maiding systems, including sheet metal design, piping, and tubing."
                    />
                </div>
            </div>
            <div className='gap' />
            <div className='mech2'>
                <div className='bimH'>
                    <h2>MECHANICAL</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={Four}
                        cardhead="AUTODESK INVENTOR"
                        description="Inventor 3D CAD software provides professiona-grade mechanical design, documentation, and product simulation tools, Powerful blend of parametric, direct. freeform, and rules-based design capabilities."
                    />
                    <Card imageUrl={Five}
                        cardhead="ANSYS"
                        description="ANSYS is a general-purpose, finite-element modeling package for numerically solving a wide variety of mechanical problems. These problems include static/dynamic, structural analysh heat transfer, and fluid problems, as well as acoustic and electromagnetic problems."
                    />
                    <Card imageUrl={Six}
                        cardhead="AUTODESK FUSION"
                        description="Autodesk Fusion 360 a cloud-based 10 CAD/CAM software package developed by Autodesic. It is designed for use in mechanical engineering, product design, and manufacturing indusnes, allowing vers to design and engineer complex models and assemblies, as well at generate toolpaths for manufacturing."
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Mechanical
