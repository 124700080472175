import React from 'react';
import './card.css'; 

const Card = ({ imageUrl, cardhead, description }) => {
  return (
    <div className="card">
      <img src={imageUrl} alt="Card" className="card-image" />
      <div className="card-content">
        <p className='cardhead'>{cardhead}</p>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default Card;
