import React, { useEffect } from 'react';

function TawkToWidget() {
  useEffect(() => {
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/65140abb0f2b18434fdade19/1hbb4js21';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }, []);

  return <></>;
}

export default TawkToWidget;
