import React from 'react'
import './courses.css'
import { Course } from '../../components'

function Courses() {
    return (
        <div className='courses section-margin' id='courses'>
        <h1 className='gradient-text'>Courses</h1>
        <Course/>
        </div>
    )
}

export default Courses
