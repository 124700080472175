import React from 'react'
import Header from './courseheader/courseheader'
import './pages.css';
import Card from './constants/card';
import One from '../../../assets/piping1.jpg';
import Two from '../../../assets/piping2.jpg';
import Three from '../../../assets/piping3.jpg';
import Four from '../../../assets/piping4.jpg';
import logo from '../../../assets/logo.jpg';
import { Footer } from '../../../containers';

function Piping() {
    return (
        <>
            <div>
                <Header />
            </div>
            <img src={logo} alt='logo' className='course-logo' />
            <div className='piping'>
                <div className='bimH'>
                    <h2>PIPING</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={One}
                        cardhead="PDMS"
                        description="PDMS(Plant Design Management System) as it is known in the 3D CAD industry,is a customizable,multi-user and multi-descipline,engineer controlled design software package for engineering,design and construction projects in offshore and onshore."
                    />
                    <Card imageUrl={Two}
                        cardhead="SMART PLANT 3D"
                        description="SP3D complete form is Smart Plant 3D Design,the software that provides 3D solutions for pipe and plant designs."
                    />
                </div>
            </div>
            <div className='gap' />
            <div className='piping2'>
                <div className='bimH'>
                    <h2>PIPING</h2>
                </div>
                <div className='cards'>
                    <Card imageUrl={Three}
                        cardhead="AUTOCAD PLANT 3D"
                        description="AutoCAD Plant 3D is a software application used for designing,modeling, and documenting industrial plant structures and processes. It is a specialized version of AutoCAD, the popular computer-aided design software used in various industries such as architecture,engineering,and construction."
                    />
                    <Card imageUrl={Four}
                        cardhead="SMART PLANT P&ID"
                        description="SmartPlant P&ID (Piping and Instrument Diagram) is a software application used in the engineering and design of industrial plants,particularly in the oil and gas and chemical industries. It is a comprehensive tool for creating,managing,and sharing P&IDs,which are important documents that show the piping,equipment, and Instrumentation of a plant and how they are connected."
                    />
                </div>
            </div>
            <div className='gap' />
            <Footer />
        </>
    )
}

export default Piping
